.link{
	position: absolute;
	top: 10px;
	right: 10px;

    display: inline-block;
    height: 32px;
    background: #333B47;
    border-radius: 4px;

    line-height: 32px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;

    cursor: pointer;

    padding-left: 12px;
    padding-right: 12px;

}

.link:hover{
    color: #000000;
    background-color: #EFBA0C;
}
