.root{
    display: inline-block;
    vertical-align: top;
}

.inputContainer{
    position: relative;
    cursor: pointer;
}

.inputContainer input{
    display: none;
}

.inputContainer .switch{
    border-radius: 20px;
    cursor: pointer;
    display: inline-block;
    height: 29px;
    position: relative;
    vertical-align: middle;
    width: 42px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    transition: background-color .2s;
    background-color: #dee3e7;

}

.inputContainer.checked .switch{
    background-color: #101928;
}

.inputContainer .switch::before{
    content: "";
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    height: 25px;
    width: 25px;
    transition: left .2s,box-shadow .2s;
}

.inputContainer.checked .switch::before{
    left: 15px;
    box-shadow: 0 0 5px #101928;
}


