.root{
    height: 100%;
    width: 100%;
}



.mobile{
    display: block;
    padding-bottom: 20px;
    max-width: 600px;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
}

.desktop{
    display: none;
    padding-left: 66px;
}

/* @media (min-width: 600px){
	.mobile{
        display: none;
    }
    
    .desktop{
        display: block;
    }
} */






.desktop table{
    width: 1100px;
    margin-top: 20px;
    table-layout: fixed;
}

.desktop caption{
    background: #161F2C;
    height: 24px;
    line-height: 24px;
}


.desktop #exchange{
    opacity: 0.5;
    font-size: 12px;
    color: #FFFFFF;
    vertical-align: middle;
}

.desktop #coin{
    font-size: 12px;
    color: #FFFFFF;
    vertical-align: middle;
}

.desktop th{
    opacity: 0.5;
    font-size: 12px;
    color: #FFFFFF;
    padding-top: 16px;
    padding-bottom: 2px;
}

.desktop td{
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
}

.desktop .bid{
    color: #23D785;
}

.desktop .ask{
    color: #E36746;
}

.desktop tbody tr:hover {
    background-color: #161F2C;
}

.desktop #exchange {
    text-transform: capitalize;
    margin-left: 5px;
    margin-right: 5px;
}

.desktop #coin{
    margin-left: 5px;
    margin-right: 5px;
}

.desktop .timeline{
    margin-left: 8px;
    cursor: pointer;
    border-radius: 2px;
    font-size: 12px;
    height: 24px;
    width: 24px;
    display: inline-block;
    color: #848E9C;

    position: relative;
    top: -1px;
}

.desktop .timeline:hover,
.desktop .timeline.active{
    color: #FFFFFF;
    background-color: #2B313A;
}	





.mobile .title{
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    margin-top: 30px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 10px;
}

.mobile .timeline{
    display: inline-block;
    width: 60px;
    height: 32px;
    background: #333B47;
    border-radius: 4px;

    line-height: 32px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;

    margin-left: 10px;
    cursor: pointer;

}

.mobile .timeline.active{
    color: #000000;
    background-color: #EFBA0C;
}

.mobile .actionBtn{
    display: inline-block;
    height: 32px;
    background: #333B47;
    border-radius: 4px;

    line-height: 32px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;

    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;

    padding-left: 12px;
    padding-right: 12px;

}

.mobile .actionBtn:hover{
    color: #000000;
    background-color: #EFBA0C;
}