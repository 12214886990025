.root{
    height: 100%;
    width: 100%;

    color: #434651;
    font-family: Trebuchet MS,roboto,ubuntu,sans-serif;
    font-size: 14px;
}

.container {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 0px;
}

/* @media (min-width: 800px){ 
    .container {
        width: 690px;
        height: auto;
        padding-top: 128px;
        padding-bottom: 128px;
        background-color: inherit;
    }
} */

.chart{
    position: relative;
    width: 100%;
    height: 100%;
}

.topFixed {
    position: absolute;
	left: 0;
	top: 0;
    width: 100%;
	z-index: 1000;
}

.menu {
    position: relative;
    width: 100%;
    background-color: #131722;

    font-family: TrebuchetMS;
    font-size: 16px;
    font-weight: 400;
    color: #b2b5be;
}

.menu::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #2A2E39;
}

.menu .btn{
    display: inline-block;
    position: relative;
    width: 38px;
    height: 38px;
    border-right: 1px solid #2A2E39;
    border-bottom: 1px solid #2A2E39;
    cursor: pointer;
    line-height: 38px;
    text-align: center;
}

.menu .btnSwitch{
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
}

.menu .btn .bg{
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    border-radius: 2px;
}

.menu .btn:hover .bg{
    background-color: #2A2E39;
}

.menu .btn .ico{
    display: inline-block;
    position: relative;
    height: 38px;
}

.menu .btn .ico svg{
    vertical-align: middle;
    margin-right: 12px;
    margin-top: -3px;
}

.menu .btn .txt{
    display: inline;
    position: relative;
    font-family: TrebuchetMS;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    opacity: 0.4;
}

.menu .btn.active .txt{
    color: #EFBA0C;
    opacity: 0.8;
}

.menu .btnSwitch.active .bg{
    background-color: #2A2E39;
}


.legend {
	padding-left: 12px;
	padding-top: 12px;
    padding-right: 100px;

    font-family: TrebuchetMS;
    font-size: 16px;
    font-weight: 400;
    color: #b2b5be;
    
}

.legend .price {
    font-size: 30px;
    font-weight: 700;
}