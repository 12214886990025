tr{
    cursor: pointer;
}

.balancesItem{
    width: 100%;
    height: 80px;
    background-color: #20262F;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 8px;
    cursor: pointer;
}

.row1{
    opacity: 0.25;
    font-size: 12px;
    color: #FFFFFF;
    margin-top: 4px;
    width: 100%;
    float: left;
}

.row2{
    font-size: 14px;
    color: #FFFFFF;
    margin-top: 10px;
    width: 100%;
    float: left;
}

.row3{
    opacity: 0.5;
    font-size: 14px;
    color: #FFFFFF;
    margin-top: 10px;
    width: 100%;
    float: left;
}

.col1,
.col2,
.col3,
.col4{
    width: 25%;
    float: left;
    text-align: left;
}

.col2,
.col3,
.col4{
    text-align: right;
}