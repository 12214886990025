.balanceHistoryItem{
    display: inline-block;
    position: relative;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 10px;
}

.balanceHistoryItem .col1{
    width: 60%;
    float: left;
    opacity: 0.5;
    font-size: 14px;
    color: #FFFFFF;
}

.balanceHistoryItem .col2{
    width: 20%;
    float: left;
    font-size: 14px;
    color: #FFFFFF;
    text-align: right;
}

.balanceHistoryItem .col3{
    width: 20%;
    float: left;
    font-size: 14px;
    color: #2EBC85;
    text-align: right;
}