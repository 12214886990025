.root{
    display: inline-block;
}

.content{
    background: #23282D;
    border-radius: 6px;

    max-width: 500px;
}


@media (min-width: 600px){ 
    .content{    
        width: 500px;
    }
}

.head{
    position: relative;
    height: 62px;
    width: 100%;
    border-bottom: 1px solid #474D57;
    padding-left: 22px;
    padding-right: 22px;
}

.head .title{
    opacity: 0.4;
    font-family: TrebuchetMS;
    font-size: 19px;
    color: #FFFFFF;
    line-height: 62px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.head .close{
    position: absolute;
    right: 0;
    top: 0;
    width: 62px;
    height: 62px;
    line-height: 62px;
    text-align: center;
    cursor: pointer;
}

.body{
    padding-top: 28px;
    padding-left: 22px;
    padding-right: 22px;

    opacity: 0.7;
    font-family: TrebuchetMS;
    font-size: 19px;
    color: #FFFFFF;
}

.footer{
    padding: 24px;
    text-align: right;
}

.btnCancel,
.btnOk{
    display: inline-block;
    background: #474D57;
    border-radius: 4px;
    min-width: 128px;
    height: 40px;
    line-height: 40px;

    padding-left: 12px;
    padding-right: 12px;

    font-family: TrebuchetMS;
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    user-select: none;
    margin-top: 10px;
}

.btnOk{
    margin-left: 10px;
    background-color: #EFBA0C;
    color: #000000;
}