.root{
    height: 100%;
    width: 100%;
}

.container {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 0px;
}

/* @media (min-width: 800px){ 
    .container {
        width: 690px;
        height: auto;
        padding-top: 128px;
        padding-bottom: 128px;
        background-color: inherit;
    }
} */


.btn{
    width: 60px;
    height: 32px;
    background: #333B47;
    border-radius: 4px;

    line-height: 32px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;

    margin-top: 10px;
    margin-left: 10px;
    cursor: pointer;

}

.btn:hover{
    color: #000000;
    background-color: #EFBA0C;
}