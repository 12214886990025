.root{
	height: 100%;
	width: 100%;
}

input{
    height: 36px;
	width: 240px;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
}

.actionBtn{
    display: inline-block;
    height: 32px;
    background: #333B47;
    border-radius: 4px;

    line-height: 32px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;

    margin-top: 10px;
    cursor: pointer;

    padding-left: 12px;
    padding-right: 12px;

}

.actionBtn:hover{
    color: #000000;
    background-color: #EFBA0C;
}
