.root{
    display: inline-block;
}

.content{
    background: #23282D;
    border-radius: 6px;

    max-width: 500px;
    width: 400px;
}


@media (min-width: 600px){ 
    .content{    
        width: 500px;
    }
}

.head{
    position: relative;
    height: 62px;
    width: 100%;
    border-bottom: 1px solid #474D57;
    padding-left: 22px;
    padding-right: 22px;
}

.head .title{
    opacity: 0.4;
    font-family: TrebuchetMS;
    font-size: 19px;
    color: #FFFFFF;
    line-height: 62px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    text-transform: capitalize;
}

.head .close{
    position: absolute;
    right: 0;
    top: 0;
    width: 62px;
    height: 62px;
    line-height: 62px;
    text-align: center;
    cursor: pointer;
}

.body{
    padding-top: 28px;
    padding-left: 22px;
    padding-right: 22px;

    font-family: TrebuchetMS;
    font-size: 19px;
    color: #FFFFFF;
}

.body .bodyCenter{
    width: 320px;
    margin: 0 auto;
}

.footer{
    padding: 24px;
    text-align: right;
}

.loading{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* background-color: #23282D; */
    z-index: 10;
}


.pairInput {
    position: relative;
    width: 100%;
    height: 40px;
}

.pairInput .leftLabel{
    float: left;
    display: inline;
    line-height: 40px;

    opacity: 0.7;
    font-family: TrebuchetMS;
    font-size: 19px;
    color: #FFFFFF;
    padding-left: 16px;
}

.pairInput .selectInput{
    float: right;
    width: 50%;
}

.selectInput{
    display: inline-block;
    position: relative;
    height: 40px;
    background: rgba(71,77,87,0.20);
    border: 1px solid #474D57;
    border-radius: 4px;
    user-select: none;
    cursor: pointer;
}

.selectInput select{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding-left: 18px;
    padding-right: 50px;
    background-color: transparent;

    font-weight: 400;
    font-size: 16px;
    color: #101928;

    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.selectInput .displayValue{
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 16px;
    padding-right: 50px;
    background-color: transparent;

    font-family: TrebuchetMS;
    font-size: 19px;
    color: #FFFFFF;
    line-height: 40px;

    z-index: 2;

    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
}

.selectInput .displayValue span{
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.selectInput .displayValueArrow{
    position: absolute;
    top: 50%;
    right: 16px;
}


.switchInput{
    position: relative;
    width: 100%;
    height: 48px;
    background: rgba(71,77,87,0.20);
    border: 1px solid #474D57;
    border-radius: 4px;
    user-select: none;
    cursor: pointer;
}

.switchInput .left,
.switchInput .right{
    position: absolute;
    float: left;
    width: 50%;
    height: 40px;
    line-height: 40px;
    top: 3px;
    background: transparent;
    box-shadow: 0 2px 6px 2px rgba(0,0,0,0);
    border-radius: 2px;

    font-family: TrebuchetMS;
    font-size: 16px;
    color: rgba(255,255,255,0.7);
    text-align: center;
}

.switchInput .left{
    left: 3px;
}

.switchInput .right{
    right: 3px;
}

.switchInput .left.active,
.switchInput .right.active{
    background: #EFBA0C;
    box-shadow: 0 2px 6px 2px rgba(0,0,0,0.30);
    color: #000000;
}


.textInput{
    position: relative;
    width: 100%;
    height: 40px;

    background: rgba(71,77,87,0.20);
    border: 1px solid #474D57;
    border-radius: 4px;
}

.textInput.disabled{
    background: rgba(71,77,87,0.70);
}

.textInput .leftLabel{
    position: absolute;
    width: 100px;
    height: 100%;
    top: 0;
    left: 0;
    padding-left: 16px;
    text-align: left;

    opacity: 0.7;
    font-family: TrebuchetMS;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 40px;
}

.textInput .rightLabel{
    position: absolute;
    width: 100px;
    height: 100%;
    top: 0;
    right: 0;
    padding-right: 16px;

    font-family: TrebuchetMS;
    font-size: 16px;
    color: #FFFFFF;
    text-align: right;
    line-height: 40px;
}

.textInput input{
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 100px;
    padding-right: 100px;
    background-color: transparent;

    font-family: TrebuchetMS;
    font-size: 16px;
    color: #FFFFFF;
    text-align: right;
    line-height: 40px;
}

/* Chrome, Safari, Edge, Opera */
.textInput input::-webkit-outer-spin-button,
.textInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.textInput input[type=number] {
  -moz-appearance: textfield;
}


.exchangeInfo{
    opacity: 0.4;
    font-family: TrebuchetMS;
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 30px;
}

.exchangeInfo .left{
    float: left;
    width: 50%;
    padding-left: 16px;
}

.exchangeInfo .right{
    float: right;
    width: 50%;
    text-align: right;
    padding-right: 16px;
}

.btnCancel,
.btnOk{
    display: inline-block;
    background: #474D57;
    border-radius: 4px;
    min-width: 128px;
    height: 40px;
    line-height: 40px;

    padding-left: 12px;
    padding-right: 12px;

    font-family: TrebuchetMS;
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    user-select: none;
    margin-top: 10px;
}

.btnOk{
    margin-left: 10px;
    background-color: #EFBA0C;
    color: #000000;
    text-transform: capitalize;
}

.maxBalance{
    position: absolute;
    top: 12px;
    right: -46px;
    font-family: TrebuchetMS;
    font-size: 16px;
    color: #EFBA0C;
    cursor: pointer;
}